import Styled from 'styled-components';

import { baseMixin } from '../../mixins';

const Wrapper = Styled.div`
  ${baseMixin};

  .table-of-contents-list {
    list-style-type: none;
    padding-left: 0;
  }

  .table-of-contents-list li {
    margin-bottom: 6px;
  }

  .table-of-contents-list a {
    color: ${({ theme }) => theme.primary.colors.darkBlue.darker};
    transition: all .3s ease;
  }

  .table-of-contents-list a:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.primary.colors.lightBlue.darker};
  }
`;

export { Wrapper };
