import React from 'react';

import { ThemeProvider } from 'styled-components';

import Layout from '../../layouts/blogLayoutCarpetCleaning';

import { Wrapper } from '../../styles/pages/blog/do-it-yourself-tips';
import theme from '../../styles/theme';

const BlogPostCarpetCare = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Wrapper>
          <h1>How to Keep Carpets Cleaner; Longer. A 7-Step Guide.</h1>
          <p style={{ fontSize: '90%', color: '#777' }}>April 30th, 2019</p>
          <p>
            Keeping your carpets clean is a crucial part of maintaining a
            beautiful and healthy home. Dirty carpets are not only unsightly,
            but they also can hold enormous amounts of dirt, insects, and
            allergens.
          </p>
          <p>
            Properly caring for your carpets will not only keep them clean, but
            it will also keep allergens out of the air that you breathe every
            day.
          </p>
          <h2>About us</h2>
          <p>
            For those who don’t know, we are a cleaning company that has been
            serving the New River Valley and Roanoke areas for 20 years.
          </p>
          <p>
            We specialize in restoration of very dirty carpets, tile, and
            upholstery. We also specialize in outstanding customer service and
            providing the best value service in the area.
          </p>
          <p>
            Below, we give a 7-step guide (and one bonus item) to helping you
            care for your carpets and keep them cleaner over a longer timeframe.
          </p>
          <ol>
            <li>
              <strong>Use a mat or rug at every home entrance</strong>. Many
              homes have welcome mats outside their front door. But you should
              also have a mat or a rug inside the front door. Furthermore, you
              should have mats or rugs inside and outside of every entrance to
              your home. This prevents the dirt from your shoes from
              transferring to the carpet. Instead, the dirt will transfer to the
              mat or rug; which is much easier to clean.
            </li>
            <li>
              <strong>Take your shoes off before walking on the carpet</strong>.
              Many people wear shoes in their homes. While this might be very
              comfortable, it’s very bad for your carpet. Even when you think
              your shoes are clean, they are probably still hiding some dirt.
              The more you wear shoes while walking on your carpet, the more
              dirt will transfer into your carpet. To avoid this problem, take
              your shoes off immediately after entering your home.
            </li>
            <li>
              <strong>Vacuum daily</strong>. Yes, you read that right. Vacuum
              every single day. Very few people do this. Most vacuum only once a
              week. That’s not enough to keep your carpets dirt-free. Not only
              will vacuuming daily keep more dirt out of your carpet, but it
              will also help improve the lifespan of your carpet.{' '}
              <em>Abrasion</em>--the scarring of carpet fibers--is mostly caused
              by walking on dirty carpet and is the primary cause of carpets
              beginning to look “worn out.”
            </li>
            <li>
              <strong>Vacuum correctly</strong>. Don’t speed through the
              vacuuming process. The entire carpet should be vacuumed. This
              includes rooms that are never used and low-traffic areas. Dirt
              will get into your carpets whether you walk on it or not. When
              vacuuming over high-traffic areas, make sure to empty the canister
              (for bagless vacuums), replace the bag when it is half full (for
              bagged vacuums), and start with a clean filter. Also vacuum over
              high traffic areas twice and do it slowly.
            </li>
            <li>
              <strong>
                Change the position of your furniture every few months
              </strong>
              . This will change the wear patterns in your carpet to help it
              last longer. It will also help prevent the furniture indentations
              from becoming permanent.
            </li>
            <li>
              <strong>Professionally clean carpets once a year</strong>.
              Vacuuming will only do so much when it comes to dirt removal.
              Professional carpet cleaning will get out what is left behind. It
              can also greatly help at removing pet hair, pet odors, pet stains,
              allergens, insects, and other contaminants in your carpet.
            </li>
            <li>
              <strong>Avoid harsh cleaners and stain removers</strong>.
              Sometimes, the mild stuff won’t do the trick. In that case, you
              might have little choice but to turn to a harsh cleaner. But if
              these cleaners--such as Resolve--can be avoided, it will be
              better. Many cleaners leave residue behind in the carpet that can
              be very difficult to remove. Some might even discolor or damage
              your carpet. These should be used as a last resort only.
            </li>
          </ol>
          <p>
            <strong>
              Bonus: Prefer nylon carpet over olefin, polypropylene, or
              polyester
            </strong>
            . This may not be an option for you since you probably already have
            carpet in your home. In the event that you are installing or
            replacing carpet, however, don’t be talked into one of these budget
            carpet types. They simply aren’t as good, don’t last as long, and
            don’t respond to cleaning as well.
          </p>
        </Wrapper>
      </Layout>
    </ThemeProvider>
  );
};

export default BlogPostCarpetCare;
